import Api from '@/services/Api'

const resource = '/parse'

export default {
  parseZipFile(credentials) {
    return Api().post(`${resource}/upload`, credentials)
  },

}

<template>
    <div class="Home">
        <page-header/>
        <div class="uk-section uk-padding-small" style="margin-bottom: 60px;">
            <div class="uk-container uk-container-small uk-padding">
                <div class="qList">
                    <div class="inform">
                        <div class="uk-open">
                            <h3>{{ $t('tmsupload') }}</h3>
                            <div class="uk-accordion-content">
                                <div class="uk-padding uk-padding-remove-left uk-padding-remove-right"
                                     style="position: relative">
                                    <input type="file" class="dragDrop"  @change="previewFileZip($event)" name="file" accept=".zip">
                                    <div class="js-upload uk-placeholder uk-text-center">
                                        <span uk-icon="icon: cloud-upload" class="uk-margin-small-right"></span>
                                        <span class="uk-text-middle" v-if="excel.fileName.length > 0">{{excel.fileName}}
                                        </span>
                                        <span class="uk-text-middle" v-else>{{$t("chEfile")}}</span>
                                        <div uk-form-custom>
                                            <span class="uk-link uk-margin-small-left">{{$t("chFile")}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="volume uk-margin-remove">File format : .zip</p>
                                        <p class="explain uk-margin-remove">Maximum size : {{$t("orgaQuestionList.mSize")}} : 200MB </p>
                                    </div>
                                    <!--<div class="uk-flex uk-flex-center uk-flex-middle">-->
                                        <!--&lt;!&ndash;<img&ndash;&gt;-->
                                                <!--&lt;!&ndash;src="../../assets/zip-file-format.png"&ndash;&gt;-->
                                                <!--&lt;!&ndash;alt=""  width="60px">&ndash;&gt;-->
                                        <!--<a  href="/uploadingDir/QuestionTemplate.xlsx"-->
                                                <!--class=""><h4 class="uk-margin-remove">-->
                                            <!--Download zip template-->
                                        <!--</h4></a>-->
                                    <!--</div>-->
                                    <button type="button" @click="parseZip()" style="float: right"
                                            class="uk-button uk-button-primary">
                                      {{$t("zupload")}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <page-footer/>
    </div>
</template>
<script>
    import ParseService from "../../services/ParseService";
    import {mapGetters} from 'vuex'
    import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
    const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

    export default {
        name: 'app',
        components: {
            PageHeader,
            PageFooter
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'currentUser'])
        },
        data() {
            return {
                excel: {
                    excelFile: null,
                    fileName: "",
                },
            };
        },
        mounted() {
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0)
            },

            async parseZip() {
                try {
                    let formData = new FormData();
                    if (this.excel.fileName == '') {
                        alert("choose zip file is empty");
                        return;
                    }
                    formData.append('file', this.excel.excelFile);
                    formData.append('orgId', this.currentUser.organizs[0].organizId);
                    const response = await ParseService.parseZipFile(formData);
                    if (response.data.status === 200) {
                        if (response.data.success == true) {
                            alert("Success");
                            this.excel.excelFile = null;
                            this.excel.fileName = ""
                        } else {
                            alert(response.data.message)
                        }
                    } else {
                        alert(response.data.message)
                    }
                } catch (e) {
                    console.log(e);
                    alert(e.response.data.message)
                }
            },
            previewFileZip(event) {

                this.excel.excelFile = null;
                this.excel.fileName = "";

                var FileSize = event.target.files[0].size / 1024 / 1024;

                if (FileSize > 200) {
                    alert("Maximum size is 200MB.");
                    return
                }

                this.excel.excelFile = event.target.files[0];
                this.excel.fileName = event.target.files[0].name;
                if (this.excel.excelFile) {
                    if (/\.(zip)$/i.test(this.excel.fileName)) {
                        console.log("nice")
                    } else {
                        alert("File format is incorrect. Please check your file!");
                        this.excel.fileName = '';

                    }
                }
            },
        }
    }
</script>
<style scoped>
    .uk-container{
        background: white;
    }
    .uk-placeholder{
        background: #E6E6E6;
    }
    .qList h3 {
        height: 30px;
        margin-right: 20px;
        padding-top: 10px;
        font-size: 1.3em;
        font-weight: 900;
        color: rgb(1, 176, 255);
        padding-left: 15px;
        border-left: 5px solid #0d9df9 ;
    }
    .qList .uk-h4 {
        font-size: 1.1em;
        font-weight: 600;
        color: rgb(1, 176, 255);
        padding-left: 15px;
        border-left: 5px solid #0d9df9 ;
    }
    .select-dropdown,
    .select-dropdown * {
        margin: 0;
        padding: 0;
        position: relative;
        box-sizing: border-box;
    }
    .select-dropdown {
        position: relative;
        background-color: #E6E6E6;
        border-radius: 4px;
        margin-right: 10px;
    }
    .select-dropdown select {
        font-size: 1rem;
        font-weight: normal;
        width: 100%;
        padding: 8px 24px 8px 10px;
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .select-dropdown select:active, .select-dropdown select:focus {
        outline: none;
        box-shadow: none;
    }
    .select-dropdown:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2px;
        border-top: 5px solid #aaa;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
    .dragDrop{
      width: 100%;
      height: 80px;
      align-items: center;
      text-align: center;
      opacity: 0;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 64px;
    }

</style>
